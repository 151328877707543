import React from 'react'
import "../assets/css/AccordionItem.css";
import { useEffect, useState } from 'react';
import Userlog from './Userlog';



const AccordionItem = ({ user, onToggle, indice }) => {

  const [usuarios, setusuarios] = useState([])
  const [title, settitle] = useState('')


  const datos = async () => {
    
    const datos = JSON.parse(user)
    setusuarios(datos.call);
    if (indice === 0){
      settitle("ADT AR")
    } else if (indice === 1) {
      settitle("ADT CL")
    } else if (indice === 2) {
      settitle("PROSEGUR AR") 
    } else if (indice === 3) {
      settitle("PROSEGUR UY") 
    } else if (indice === 4) {
      settitle("PROSEGUR CL") 
    }
  }

  useEffect(() => {
    datos()
}, []) 

  return (
    <div>
      <li className="accordion_item">
        <button className="button" onClick={onToggle}>
          {title}
        </button>
        <div
        >
          <div className="answer">
              {usuarios.map((usuario) => (
                <div key={usuario} className='accordion_user'>
                  <Userlog
                    user={usuario}
                  />
                </div>
              ))}
          </div>
        </div>
      </li>
    </div>
  )
}

export default AccordionItem