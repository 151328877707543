import React from 'react'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useForm } from "../hooks/useForm";
import "../assets/css/login.css";
import md5 from 'md5';



const Login = () => {
    const initialForm = {
        username: "",
        password: "",
      };

    const [msgerror, setmsgerror] = useState(null)
    const [userType, setuserType] = useState(null)
    const [form, handleInputChange] = useForm(initialForm); // Hook para uso del custom hook useForm
    const semilla = ['MasterDealer', 'Pokemona47', 'RafaNadal53']

    //Valido los datos del formulario
    const validationsForm = (form) => {
        let errors = {};        
        if (!form.username.trim()) {
            errors.username = "El campo 'Usuario' es requerido";
        }
        if (!form.password.trim()) {
            errors.password = "El campo 'Password' es requerido";
        }
        return errors;
    };

    const RegistrarUsuario = async (e) =>{
        e.preventDefault();
        const error = validationsForm(form);
        if (Object.keys(error).length === 0){
            if (form.username !== process.env.REACT_APP_USER  || form.password !== process.env.REACT_APP_PASS){
                setmsgerror('La contraseña o el usuario es incorrecto')
            }else{
                var x = Math.floor(Math.random()*semilla.length);
                var hash = md5(semilla[x])
                window.sessionStorage.setItem("token_user", hash);
                setuserType(1)
            }
        }else{
            console.log(error.username)
            if(error.username !== undefined && error.password !== undefined){
                setmsgerror('Los campos "usuario" y "password" no pueden estar vacios')
            }else{
                if(error.username !== undefined){
                    setmsgerror('El campo "usuario" no puede estar vacio')
                }else{
                    setmsgerror('El campo "password" no puede estar vacio')
                }
            }
        }
    }
    return (
        <div className='form-position'>
            <div className='login-container'>
                <div className='form'>
                    <form onSubmit={RegistrarUsuario} className='contact-form'>
                        <br/>
                        <input
                            type="text"
                            name="username"
                            id="username"
                            placeholder="Username"
                            value={form.fullName}
                            onChange={handleInputChange}
                            required
                        />
                        <br/>
                        <input
                            name="password"
                            id="password"
                            placeholder="Password"
                            type='password'
                            value={form.fullName}
                            onChange={handleInputChange}
                            required
                        />
                    <button type='submit'>Login</button>
                    </form>
                </div>
                <div className='message'>
                    {
                        msgerror !== null ?
                        (
                            <div>
                                {msgerror}
                            </div>
                        ):
                        (
                            <span>
                            </span>
                        )
                    }
                </div>
                <div>
                    {
                        userType !== null ?
                        (
                            <div>
                                <Navigate to='/home'/>
                            </div>
                        ):
                        (
                            <span></span>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default Login
