import React from 'react'
import '../assets/css/user.css'
import greenCircle from '../assets/img/green-circle.svg'
import redCircle from '../assets/img/red-circle.svg'

const Userlog = (user) => {
  
  const data1 = user.user.split("<")
  const data2 = data1[1].split("/")
  const nombre = data1[0]
  const interno = data2[1]
  const estado = data2[2]
  // const descripcion_estado = data2[3]
  const estado_ivr = data2[4] 


  return (
    <div className='container-user'>
      {estado_ivr === "NO_en_llamada" ?
      (
        <>
          {estado === "0" ^ estado === "1"?
            (
              <>
                <span className='status-button'>
                  <img src={greenCircle} alt="green-circle" width={"20px"} height={"20px"}/>
                </span>
                {window.screen.width < 550 ? (
                  <>
                    <span className='user-name'>
                      {nombre}
                    </span>
                    <br/>
                    <span className='user-phone'>
                      Interno: {interno}
                    </span>
                    <span className='user-status'>
                      Disponible
                    </span>
                  </>
                ):(
                  <>
                    <span className='user-name'>
                      {nombre}  
                    </span>
                    <span className='user-phone'>
                      Interno: {interno}
                    </span>
                    <span className='user-status'>
                      Disponible
                    </span>
                  </>
                )}
              </>
            ):(
              <>
                <span className='status-button'>
                  <img src={redCircle} alt="red-circle" width={"20px"} height={"20px"}/>
                </span>
                <span className='user-name'>
                  {nombre}  
                </span>
                <span className='user-phone'>
                  Interno: {interno}
                </span>
                <span className='user-status'>
                  En llamada
                </span>
              </>
            )
          }
        </>
      ):(
        <>
          <span className='status-button'>
            <img src={redCircle} alt="red-circle" width={"20px"} height={"20px"}/>
          </span>
          {window.screen.width < 550 ? (
              <>
                <span className='user-name'>
                  {nombre}  
                </span>
                <br/>
                <span className='user-phone'>
                  Interno: {interno}
                </span>
                <span className='user-status'>
                  En llamada - IVR
                </span>
              </>
            ):(
              <>
                <span className='user-name'>
                  {nombre}  
                </span>
                <span className='user-phone'>
                  Interno: {interno}
                </span>
                <span className='user-status'>
                  En llamada - IVR
                </span>
              </>
            )}
        </>
      )  
    }
    </div>
  )
}

export default Userlog