import React, { useState } from 'react'
import "../assets/css/home.css";
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
// import Constantes from "./Constantes";
import AccordionItem from "./AccordionItem";
import Loader from './Loader';


const Home = () => {

  // Variable para almacenar el estado del loader
  const [loading, setLoading] = useState(false);

  const [users, setusers] = useState([])

  const reload_page = async () => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(30000)
    window.location.reload();
  }

  const obtener_usuarios = async () =>{
    if(window.sessionStorage.getItem("token_user") !== null){
      setLoading(true);
      // const content = await fetch(`${Constantes.RUTA_API}/obtener_usuarios.php`);
      const content = await fetch(`obtener_usuarios.php`);
      const response = await content.json();
      setusers(response)
      setLoading(false);
      }
    }

  useEffect(() => {
    obtener_usuarios()
    reload_page()
}, []) 
  

  return (
    <div>
      {
        window.sessionStorage.getItem("token_user") !== null?
        (
          <div className='container'>
            <h1 className='heading grid-responsive'>Cola de Logueados</h1>
              {users.length > 0 ? (
                  <ul className="accordion">
                    {users.map((user, index) => (
                      <AccordionItem
                        key={index}
                        user={user}
                        indice={index}
                      />
                    ))}
                  </ul>
            ) : (
              <span>
                {loading && <Loader />}
              </span>
            )}
          </div>
        ):(
          <div>
              <Navigate to='/login'/>
          </div>
        )
      }
     
    </div>
  )
}

export default Home
