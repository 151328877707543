import React from 'react'
import "../assets/css/topbar.css";

const TopBar = () => {
  return (
    <div>
      {window.innerWidth <= 1000 ?(
        <div className="topbar-container">
          <div>
            <h6 className='navbar-brand'>IVR</h6>
          </div>
        </div>
      ) : (
        <div className="topbar-container">
          <div>
            <h6 className='navbar-brand'>IVR</h6>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopBar